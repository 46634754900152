<ng-template class="modal" #content let-modal>
	<div class="modal-header">
		<h4 class="modal-title">Cart</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
        <stepper-component></stepper-component>
          
	</div>

    
</ng-template>

