import { ListItemInterface } from "./ListItemInterface";

export class CurrentOrderUser{
    shopper_firstname:string;
    shopper_lastname:string;
    shopper_phone:string;
    date:string;

    todo:ListItemInterface[]
    breakfast:ListItemInterface[]
    bread:ListItemInterface[]
    pet:ListItemInterface[]
    produce:ListItemInterface[]
    beverages:ListItemInterface[]
    international:ListItemInterface[]
    baking:ListItemInterface[]
    grains:ListItemInterface[]
    snacks:ListItemInterface[]
    deli:ListItemInterface[]
    bakery:ListItemInterface[]
    meat:ListItemInterface[]
    household:ListItemInterface[]
    health:ListItemInterface[]
    frozen:ListItemInterface[]
    dairy:ListItemInterface[]
    completed:ListItemInterface[]

    constructor(){
        this.shopper_firstname = ""
        this.shopper_lastname = ""
        this.shopper_phone = ""
        this.date = ""
        this.todo = []
        this.breakfast = []
        this.bread = []
        this.pet = []
        this.produce = []
        this.beverages = []
        this.international = []
        this.baking = []
        this.grains = []
        this.snacks = []
        this.deli = []
        this.bakery = []
        this.meat = []
        this.household = []
        this.health = []
        this.frozen = []
        this.dairy = []
        this.completed = []
    }
}