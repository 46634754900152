
export class ListToDB{
    token:string;
    list:string;
    date:string;
    shopperId:number;
    note:string;

    constructor(){
        this.list = "";
        this.token="";
        this.date="";
        this.shopperId=0;
        this.note = ""
    }
}