import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Environment } from "./Environment";
import { JwtUserResponse } from "src/models/JwtUserResponse";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RegisterService } from "src/register/register.service";
import { TokenResponse } from "src/models/TokenResponse";
import { NavService } from "../nav/nav.service";
import { ProfileService } from "../../profile/profile.service";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { ListItemInterface } from "../../list/models/ListItemInterface";
import { CurrentOrderUser } from "../../list/models/CurrentOrderUser";
import { CurrentOrderUserClassWithUpdateMessage } from "../../list/models/CurrentOrderUserClassWithUpdateMessage";

@Injectable({
    providedIn: 'root'
  })
  export class EnvironmentService{
    activeOrderSubscription:any=false
    activeAdminOrderSubscription:any=false
    email:string;
    user:JwtUserResponse;
    hasToken:boolean = false
    loginTimer:any
    logoutTimer:any
    userLoggedIn:BehaviorSubject<boolean>=new BehaviorSubject(false);
    userLoggedIn$:Observable<boolean>
    activeOrderClient:BehaviorSubject<CurrentOrderUser>=new BehaviorSubject(new CurrentOrderUser());
    activeOrderClient$:Observable<CurrentOrderUser>
    activeAdminOrderClient:BehaviorSubject<CurrentOrderUserClassWithUpdateMessage>=new BehaviorSubject(new CurrentOrderUserClassWithUpdateMessage());
    activeAdminOrderClient$:Observable<CurrentOrderUserClassWithUpdateMessage>
    signOutSnackbar:BehaviorSubject<boolean>=new BehaviorSubject(false);
    signOutSnackbar$:Observable<boolean>
    serverUrl = environment.socketUrl+"/our-websocket"
  stompClient;
  updateLog:string[]=[]

  todo: ListItemInterface[]=[]
  breakfast: ListItemInterface[]=[]
  bread: ListItemInterface[]=[]
  pet: ListItemInterface[]=[]
  produce: ListItemInterface[]=[]
  beverages: ListItemInterface[]=[]
  international: ListItemInterface[]=[]
  baking: ListItemInterface[]=[]
  grains: ListItemInterface[]=[]
  snacks: ListItemInterface[]=[]
  deli: ListItemInterface[]=[]
  bakery: ListItemInterface[]=[]
  meat: ListItemInterface[]=[]
  household: ListItemInterface[]=[]
  health: ListItemInterface[]=[]
  frozen: ListItemInterface[]=[]
  dairy: ListItemInterface[]=[]
  completed: ListItemInterface[]=[]
  

    constructor(private http:HttpClient, private navService:NavService, private profileService:ProfileService, private router:Router, private registerService:RegisterService){
      this.userLoggedIn$ = this.userLoggedIn.asObservable();
      this.signOutSnackbar$ = this.signOutSnackbar.asObservable();
      this.activeOrderClient$ = this.activeOrderClient.asObservable();
      this.activeAdminOrderClient$ = this.activeAdminOrderClient.asObservable();
    }

    getLogin(){
      return sessionStorage.getItem("log");
    }

    signOut(){
      this.router.navigate(['/','home']);
      this.navService.cartVisibilityFromUser.next(false)
      this.profileService.setSignOut(true);
      this.removeUser();
      this.navService.cartCount.next("");
      this.userLoggedIn.next(false)
      this.stopLoginTimer()
      this.stopLogoutTimer()
      this.signOutSnackbar.next(true)
    }

    refreshLogin():Observable<TokenResponse>{
      return this.http.get<TokenResponse>(environment.apiUrl + "/api/v1/auth/refresh")
    }

    setToken(token:string){
      sessionStorage.setItem("token",token);
      //resets timers after setToken call
      //window.location.reload()
    }
    
    setEnvironment(){
        sessionStorage.setItem("log", "1");
        this.registerService.getUser().subscribe( (d) => {
          this.registerService.setAdmin(d);
          window.location.reload()
      });
        
    }

    getEnvironment():Environment{
        return {
          token:sessionStorage.getItem("token"),
          log:sessionStorage.getItem("log")
        }
      }

    setUser(response:JwtUserResponse){
      this.user = response;
    }

    removeUser(){
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("log");
      sessionStorage.removeItem("admin")
    }

    startLoginTimer(){
      this.loginTimer = setInterval(()=>{
        //get new token
        //stop logout timer
        //after 15 min
        //logout timer starts when refresh happens
        this.refreshLogin().subscribe(d=>{
          if(d.token == "expired"){
            this.signOut()
            console.log(d.token)
          }else{
            this.setToken(d.token)
            console.log(d.token)
          }
        })
      },20 * 60 * 1000) //900000 //2 * 60 * 1000
    }

    startLogoutTimer(){
      console.log("in logout")
      this.logoutTimer = setTimeout(()=>{
        //if the timer hits sign them out
        this.signOut()
      },10 * 60 * 1000)
    }

    stopLogoutTimer(){
      clearTimeout(this.logoutTimer)
    }

    stopLoginTimer(){
      clearInterval(this.loginTimer)
    }

    updateUserData(userData:string,choice:string):Observable<JwtUserResponse>{
      return this.http.post<JwtUserResponse>(environment.apiUrl + "/api/v1/auth/updateUser", {userUpdate:userData,choice:choice})
    }

    ////// WEBSOCKET FUNCTIONALITY //////

    initializeWebSocketConnection() {

      let ws = new SockJS(this.serverUrl);
      this.stompClient = Stomp.over(ws);
      let that = this;
      //this.stompClient.debug = null;
      this.stompClient.connect({ token: this.getEnvironment().token }, function (frame) {
        that.stompClient.subscribe("/user/topic/messages", function (message) {
          if (message.body == "false") {
            that.navService.cartVisibility.next({ hasActive: false, hasCurrentOrder: false })
            that.navService.cartCount.next("0")
          }else if(message.body == "true"){
            that.navService.cartVisibility.next({ hasActive: true, hasCurrentOrder: true })
          }
  
  
        });
  
    })
    }

    checkActiveSubscriptions(){
      //console.log(this.stompClient.subscriptions.activeOrder)
      if(this.stompClient.subscriptions?.activeOrder == undefined){
        this.addActiveOrderSubscription()
      }
    }

    checkActiveAdminSubscriptions(){
      if(this.stompClient.subscriptions.activeAdminOrder == undefined){
        this.addActiveAdminOrderSubscription()
      }
    }

    addActiveOrderSubscription(){
      //test if the order is in active state
      //if active add a subscription to the websocket
      let activeOrderUpdate: CurrentOrderUser
      this.navService.cartVisibility$.subscribe(listState => {
        if(listState.hasActive){
          //add subscription
          let that = this
          this.activeOrderSubscription = this.stompClient.subscribe("/user/topic/activeOrder", function (message) {
            activeOrderUpdate = JSON.parse(message.body)
            that.activeOrderClient.next(activeOrderUpdate)
          },{id:'activeOrder'})
        }
      })
    }

    addActiveAdminOrderSubscription(){
      let activeOrderUpdate: CurrentOrderUserClassWithUpdateMessage
      let that = this
      this.activeAdminOrderSubscription = this.stompClient.subscribe("/user/topic/activeOrder", function (message) {
        activeOrderUpdate = JSON.parse(message.body)
        that.activeAdminOrderClient.next(activeOrderUpdate)
      },{id:"activeAdmin"})
    }

    deleteActiveOrderSubscription(){
      if(this.activeOrderSubscription != false){
        this.activeOrderSubscription.unsubscribe()
      }
    }

    deleteActiveAdminOrderSubscription(){
      if(this.activeAdminOrderSubscription != false){
        this.activeAdminOrderSubscription.unsubscribe()
      }
    }

    sendActiveOrderMessage(updateMessage:string,currentOrderDate:string,lists:any) {
      this.stompClient.send('/ws/test', {}, JSON.stringify(new CurrentOrderUserClassWithUpdateMessage(
        this.getEnvironment().token,
        currentOrderDate,
        updateMessage,
        lists.todo,
        lists.breakfast,
        lists.bread,
        lists.pet,
        lists.produce,
        lists.beverages,
        lists.international,
        lists.baking,
        lists.grains,
        lists.snacks,
        lists.deli,
        lists.bakery,
        lists.meat,
        lists.household,
        lists.health,
        lists.frozen,
        lists.dairy,
        lists.completed
      )))
    }
  }