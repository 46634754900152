
<div (window:resize)="onResize($event)"></div>
<div class="body">
    
    <img class="homeImg" src="../../assets/img/family2-min.png">
    
    <div class="whyContainer py-3">
            <div class="whyTitle">Why ShoppingTally?</div>
            <div class="text-center mt-2 mb-5 whyText">We're 100% committed to complete customer satisfaction. Enjoy the extra time with your family and friends! Let us take care of the shopping! We've got you covered!!!</div>
            <div class="container whyColumnContainer">
                <div class="row gx-2 gy-2 d-flex justify-content-center">
                    <div class="whyDataContainer col-12 col-sm-6 col-lg-4">
                        <img class="whyDataIcons" src="../../assets/gif/apple.gif">
                        <div class="test">No Annual or Monthly Fees</div>
                    </div>
                    <div class="whyDataContainer col-12 col-sm-6 col-lg-4">
                        <img class="whyDataIcons" src="../../assets/gif/guava.gif">
                        <div class="test">On Avg 12% Less Than <svg class="mb-2" width="25px" height="25px" xmlns="http://www.w3.org/2000/svg" id="logosandtypes_com" viewBox="0 0 150 150"><path d="M0 0h150v150H0V0z" fill="none"/><path d="M77.6 140.6c-13.8 0-27.4-2.6-40.2-7.6-6.6-2.6-10.7-9.2-10.2-16.3L30.8 58l17.9 1.1-3.5 57.7c20.9 7.9 44 7.9 64.9-.1-.4-6.5-2.7-40.5-4.2-60.4-17.9 9-33 9.7-44.1 1.9-7-5-11.1-13-11-21.6-.1-9 4.2-17.4 11.5-22.7 6.4-4.7 14.6-6.2 22.2-4.1 8.5 2.3 15.9 8.8 19.9 17.4l-16.3 7.5c-1.7-3.8-4.9-6.7-8.2-7.6-2.5-.7-5.2-.1-7.3 1.5-2.5 1.9-4 4.9-3.9 8-.1 2.7 1.2 5.4 3.4 7 6.8 4.8 20.6 1.1 36.8-9.7 2.8-1.9 6.5-2 9.4-.3 4.2 2.4 4.4 5.8 4.7 10.4l5 72.6c.6 7.1-3.6 13.8-10.3 16.4-12.7 5-26.4 7.6-40.1 7.6z" fill="#23cc6b"/></svg> or <img class="mb-2" style="width: 22px; height: 22px;" src="../../assets/img/instacartLogo.png"><span>*</span></div>
                    </div>
                    <div class="whyDataContainer col-12 col-sm-6 col-lg-4">
                        <img class="whyDataIcons" src="../../assets/gif/berries.gif">
                        <div class="test">Over 10k Five Star Reviews</div>
                    </div>
                    <div class="whyDataContainer col-12 col-sm-6 col-lg-4">
                        <img class="whyDataIcons" src="../../assets/gif/tropical_fruit.gif">
                        <div class="test">7+ Years of Personal Shopping</div>
                    </div>
                    <div class="whyDataContainer col-12 col-sm-6 col-lg-4">
                        <img class="whyDataIcons" src="../../assets/gif/lemon.gif">
                        <div class="test">Delivery Within the Hour</div>
                    </div>
                    <div class="whyDataContainer col-12 col-sm-6 col-lg-4">
                        <img class="whyDataIcons" src="../../assets/gif/watermelon.gif">
                        <div class="test">24/7 Emergency Service</div>
                    </div>
                </div>
            </div>
            <div class="asteriskNote">* Shipt, Instacart and even Publix Pick Up charge an average markup of 25-30%. We charge an 18% fee and pay for the order upfront for you. We'll let you know the total including the fee on the way to your home. Tips are much appreciated, but not required. We accept payment from Venmo, PayPal, Apple Pay, cash or check. Attention to detail by picking the finest produce and meats is our specialty! There is also privacy and security by always having the same two reliable and trustworthy shoppers.</div>
        
    </div>

    <!-- <video autoplay muted loop>
        <source src="../../assets/videos/test.mp4" type="video/mp4">
    </video> -->
    <div class="storesContainer mt-5">
        <h1>Available Stores</h1>
        <img class="storeImg" src="../../assets/img/revisedstore-min.png">
        <div class="bottomBorder"></div>
        
    </div>

    <div class="container whatsNewSection mt-5 mb-5">
        <h1 class="mb-4">What's New</h1>
        <div class="row gx-4 gy-4 whatsNewContainer">
            <div class="whatsNewDataContainer col-lg-6" *ngFor="let item of array">
                <img [src]=item.imageData>
                <div class="whatsNewData">
                    <h2>{{item.title}}</h2>
                    <h5>{{item.description}}</h5>
                </div>
            </div>
        </div>
    </div>
    
    <div class="testimonialsSection" >
        <video autoplay loop playsinline muted webkit-playsinline width="1920px" height="600px">
            <source src="../../assets/videos/testBack.mp4" type="video/mp4">
        </video>
        <h1 class="testTitle mt-2">Testimonials</h1>
        <div class="animationContainer">
            <div *ngIf="mobileResolution$ | async">
                <div #test class="test1Vertical testContainerGlobal">
                    <div class="mt-2 testText">"Had a fabulous experience with Jay today. I've been out of town for several months and had to reorient myself to the process. Jay was extremely helpful and even squeezed me in on his busy Saturday. Thank you Jay for all you do."</div>
                    <div class="iconContainer">
                        <div class="testIcon">
                            <div>KK</div>
                        </div>
                    </div>
                </div>
                <div #test class="test2Vertical testContainerGlobal">
                    <div class="mt-2 testText">"Jay has been shopping for us for three years. He is a true professional and takes pride in knowing your likes/dislikes so you don't have to compromise on what you would choose if you were shopping. The ease of using his service allows me time for other things which is a huge value for me."</div>
                    <div class="iconContainer">
                        <div class="testIcon">
                            <div>BF</div>
                        </div>
                    </div>
                </div>
                <div #test class="test3Vertical testContainerGlobal">
                    <div class="mt-2 testText">"I've been using Jay as my personal shopper for about 2 months! He is fantastic! He pays attention to details! He picks quality items. He communicates if they are out of a requested item & he's always on time! Jay is amazing!"</div>
                    <div class="iconContainer">
                        <div class="testIcon">
                            <div>TD</div>
                        </div>
                    </div>
                </div>
                <div #test class="test4Vertical testContainerGlobal">
                    <div class="mt-2 testText">"Jay is the best! He always gets exactly what we ask for and even remembers our favorite items and makes sure we get what we need and what we want! We would not know what to do without Jay and would recommend him to everyone to make their life better and easier."</div>
                    <div class="iconContainer">
                        <div class="testIcon">
                            <div>SB</div>
                        </div>
                    </div>
                </div>
                <div #test class="test5Vertical testContainerGlobal">
                    <div class="mt-2 testText">"Shopping in-store with two young kids is not ideal. We've been working with Jay for quite a while now, and he has taken all the stress of shopping away. He's thorough, timely, trustworthy, and quickly learns preferences/dislikes. Makes my life so much easier!"</div>
                    <div class="iconContainer">
                        <div class="testIcon">
                            <div>AG</div>
                        </div>
                    </div>
                </div>
                <div #test class="test6Vertical testContainerGlobal">
                    <div class="mt-2 testText">"Jay took so much time going over Any List and making sure I understood his services. I have used Jay for over a year and I won't deal with anyone else. Jay you are the best!"</div>
                    <div class="iconContainer">
                        <div class="testIcon">
                            <div>SR</div>
                        </div>
                    </div>
                </div>
                <div #test class="test7Vertical testContainerGlobal">
                    <div class="mt-2 testText">"Jay is amazing! He has been our personal shopper for years and we have never had an issue. He goes out of his way to assure he gets exactly what we like. I recommend this business with no reservations."</div>
                    <div class="iconContainer">
                        <div class="testIcon">
                            <div>RL</div>
                        </div>
                    </div>
                </div>
            </div>

            
            <ng-container *ngIf="!(mobileResolution$ | async)">
                <div #test class="test1Horizontal testContainerGlobalHorizontal">
                    <div class="mt-2 testText">"Had a fabulous experience with Jay today. I've been out of town for several months and had to reorient myself to the process. Jay was extremely helpful and even squeezed me in on his busy Saturday. Thank you Jay for all you do."</div>
                    <div class="iconContainer">
                        <div class="testIcon">
                            <div>KK</div>
                        </div>
                    </div>
                </div>
                <div #test class="test2Horizontal testContainerGlobalHorizontal">
                    <div class="mt-2 testText">"Jay has been shopping for us for three years. He is a true professional and takes pride in knowing your likes/dislikes so you don't have to compromise on what you would choose if you were shopping. The ease of using his service allows me time for other things which is a huge value for me."</div>
                    <div class="iconContainer">
                        <div class="testIcon">
                            <div>BF</div>
                        </div>
                    </div>
                </div>
                <div #test class="test3Horizontal testContainerGlobalHorizontal">
                    <div class="mt-2 testText">"I've been using Jay as my personal shopper for about 2 months! He is fantastic! He pays attention to details! He picks quality items. He communicates if they are out of a requested item & he's always on time! Jay is amazing!"</div>
                    <div class="iconContainer">
                        <div class="testIcon">
                            <div>TD</div>
                        </div>
                    </div>
                </div>
                <div #test class="test4Horizontal testContainerGlobalHorizontal">
                    <div class="mt-2 testText">"Jay is the best! He always gets exactly what we ask for and even remembers our favorite items and makes sure we get what we need and what we want! We would not know what to do without Jay and would recommend him to everyone to make their life better and easier."</div>
                    <div class="iconContainer">
                        <div class="testIcon">
                            <div>SB</div>
                        </div>
                    </div>
                </div>
                <div #test class="test5Horizontal testContainerGlobalHorizontal">
                    <div class="mt-2 testText">"Shopping in-store with two young kids is not ideal. We've been working with Jay for quite a while now, and he has taken all the stress of shopping away. He's thorough, timely, trustworthy, and quickly learns preferences/dislikes. Makes my life so much easier!"</div>
                    <div class="iconContainer">
                        <div class="testIcon">
                            <div>AG</div>
                        </div>
                    </div>
                </div>
                <div #test class="test6Horizontal testContainerGlobalHorizontal">
                    <div class="mt-2 testText">"Jay took so much time going over Any List and making sure I understood his services. I have used Jay for over a year and I won't deal with anyone else. Jay you are the best!"</div>
                    <div class="iconContainer">
                        <div class="testIcon">
                            <div>SR</div>
                        </div>
                    </div>
                </div>
                <div #test class="test7Horizontal testContainerGlobalHorizontal">
                    <div class="mt-2 testText">"Jay is amazing! He has been our personal shopper for years and we have never had an issue. He goes out of his way to assure he gets exactly what we like. I recommend this business with no reservations."</div>
                    <div class="iconContainer">
                        <div class="testIcon">
                            <div>RL</div>
                        </div>
                    </div>
                </div>
            </ng-container>
            
        </div>


    </div>

    
</div>

        
    
        
