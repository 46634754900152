<ng-container (window:resize)="onResize($event)"></ng-container>
<ngb-datepicker #test
	[displayMonths]="displayMonths"
	[showWeekNumbers]="showWeekNumbers"
	[outsideDays]="outsideDays"
	#dp 
	[(ngModel)]="model" 
	[navigation]="navigation"
	[startDate]="startDate"
	(dateSelect)="setCurrentDate()"
>

</ngb-datepicker>

