


<div #we class="confettiContainer"></div>
<mat-stepper #stepper *ngIf="showStepper" class="stepperContainer" labelPosition="bottom">
  
  <mat-step label="Review">
      <div class="tableContainer">
          <div class="rowContainer pt-2 pb-3" *ngFor="let item of yes">
            <img *ngIf="item.image != 'undefined' && item.image != 'null' && item.image != '' else imageTemplate" [src]=item.image height="70px" width="70px">
            <ng-template #imageTemplate>
              <img src="../../../assets/img/bag.png" height="auto" width="70px">
            </ng-template>
            <div style="width: 150px; text-align: center;">
              {{item.name}}
            </div>
            <app-quantity [itemQuantity]=item.quantity (quantityEvent)="updateQuantity($event,item.name,item.quantity)"></app-quantity>
            <div class="delete" (click)="removeItem(item.name)">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"/>
                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"/>
              </svg>
            </div>
              
          </div>
          

          <h3 class="noItemsText" *ngIf="!(cartHasItems | async)">No items in your cart</h3>
        </div>
    <div class="stepperBtnsContainer">
      <button [disabled]="!(cartHasItems | async)" mat-button matStepperNext (click)="addList(); increaseStepCounter()">Next  
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
        </svg>
      </button>
    </div>
  </mat-step>

  <mat-step label="Shopper">
    
    <div class="shopperContainer">
      <div class="p-2 label-width">Choose your shopper:</div>
      <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
        <mat-button-toggle value="bold" (click)="shopperPicked(1); getShopperDates(1);">Jay</mat-button-toggle>
        <div style="align-self: center;">or</div>
        <mat-button-toggle value="underline" (click)="shopperPicked(2); getShopperDates(2)">Josh</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="noteContainer mt-2">
      <div style="margin-right: 10px;">Note:</div>
      <textarea #note placeholder="Leave a note for the shopper" rows="4" cols="27"></textarea>
    </div>
    
    <div class="stepperBtnsContainer">
      <button mat-button matStepperPrevious (click)="decreaseStepCounter()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-circle" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
        </svg>
        Back
      </button>
      <mat-divider [vertical]="true"></mat-divider>
      <button [disabled]="!isShopperPicked" mat-button matStepperNext (click)="increaseStepCounter(); addNoteToOrder(note.value)">Next
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
        </svg>
      </button>
    </div>
  </mat-step>
  
  <mat-step label="Schedule" >
    
    
    <div class="body">
      
      <app-datepicker *ngIf="isShopperPicked" (newItemEvent)="getDate($event)"></app-datepicker>
      <div class="timeSlotContainer" [ngClass]="datePickedFromCalendar ? 'showTimeSlot' : 'timeSlotContainer'">
        <div class="mt-2 mb-3">Available time slots for {{currentShopper}} on: {{currentDate | date}}</div>
        <div class="container" #hourContainer>
          <div class="row gx-5 gy-2">
            <div  class="col-3 col-sm-3 col-md-2 col-lg-2" *ngFor="let i of [10,11]" >
              <div matRipple #hours class="hours" (click)="setHour(i,hours);dateIsSelected()">{{i}}am</div>
            </div>
            <div class="col-3 col-sm-3 col-md-2 col-lg-2" *ngFor="let i of [12,1,2,3,4,5,6,7,8,9]">
              <div matRipple #hours class="hours" (click)="setHour(i,hours);dateIsSelected()">{{i}}pm</div>
            </div>
            
          </div>
        </div>
      </div>
    
      <div class="appointmentContainer mt-3" *ngIf="dateSelected">
        <div>Your selected appointment is: {{currentDate | date}} at {{selectedHour}}</div>
      </div>
      
    </div>
    <div class="stepperBtnsContainer">
      <button mat-button matStepperPrevious (click)="resetHours();decreaseStepCounter();">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-circle" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
        </svg>
        Back
      </button>
      <mat-divider [vertical]="true"></mat-divider>
      <button [disabled]="!dateSelected" mat-button (click)="sendList(stepper);test();resetStepCounter()">Finalize
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
        </svg>
      </button>
    </div>
    
  </mat-step>
  <!-- <mat-step label="Finalize">
    <div class="body">
      <mat-table [dataSource]="yes" class="table">
        <ng-container matColumnDef="image">
          <mat-header-cell *matHeaderCellDef> Image</mat-header-cell>
          <mat-cell *matCellDef="let row"> 
            <img *ngIf="row.image != 'undefined' && row.image != 'null' else imageTemplate" [src]=row.image height="auto" width="70px"> 
            <ng-template #imageTemplate>
              <img src="../../../assets/img/bag.png" height="auto" width="70px">
            </ng-template>
          </mat-cell>
          
        </ng-container>  
        
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> Name</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.name}} </mat-cell>
          </ng-container>
        
          <ng-container matColumnDef="quantity">
            <mat-header-cell *matHeaderCellDef> Quantity</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.quantity}} </mat-cell>
          </ng-container>
    
          
        
          
          <mat-header-row *matHeaderRowDef="['image','name','quantity']"></mat-header-row>
          <mat-row *matRowDef="let row; columns: ['image','name', 'quantity']"></mat-row>
        </mat-table>

      
      <div class="finalAppointmentContainer">
        Appointment: {{currentDate | date}} at {{selectedHour}}
      </div>
    </div>
    <div class="stepperBtnsContainer" >
      <button mat-button matStepperPrevious (click)="decreaseStepCounter()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-circle" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
        </svg>
        Back
      </button>
      <mat-divider [vertical]="true"></mat-divider>
      <button mat-button matStepperNext (click)="sendList();test();resetStepCounter()">Finalize
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
        </svg>
      </button>
    </div>
    
  </mat-step> -->

</mat-stepper>


