<div class="twilioBody">
    <h1>Twilio opt-in form</h1>
    <form [formGroup]="twilioForm">
        <label for="first-name">First Name: </label>
        <input id="first-name" type="text" formControlName="firstName">

        <label for="last-name">Last Name: </label>
        <input id="last-name" type="text" formControlName="lastName">

        <h5 class="mt-4">I agree to receive SMS from (855)643-1842, message and data rates may apply. Reply STOP to opt-out, HELP for help</h5>
        
        <label class="acceptLabel mt-2">Click here to accept: </label>
        <input type="checkbox" formControlName="acceptance">

        <button class="mt-2" mat-raised-button>Submit</button>
    </form>
</div>

